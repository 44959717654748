import React from 'react';
import { motion } from 'framer-motion';
import './style.css';

const About = () => {
    return (
        <motion.div
            className="about-section"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1.5 }}
        >
            <h2>Why AI Enabling?</h2>
            <p>Thinking is hard, Communication even harder.</p>
            <p>We speed up people thinking and communication
                to the limits not achievable before 2022</p>
            <p>The shortest optimal path to get things finally DONE</p>

            {/*<h2>Why AI Enabling?</h2>*/}
            {/*<p>AI won't replace humans. However, humans who effectively use AI will replace those who don't.</p>*/}
            {/*<p>Do you find yourself feeling exhausted at the end of the day, yet with little progress on your tasks? Imagine offloading most of your cognitive tasks to AI, freeing up your mental bandwidth for what truly matters.</p>*/}
            {/*<p>AI is not here to replace us but to assist us, helping solve more problems and boosting our productivity.</p>*/}
        </motion.div>
    );
};

export default About;
