import React from 'react';
import { motion } from 'framer-motion';
import './style.css';

function ShowcaseVideo() {
    return (
        <motion.div
            className="showcase-video-section"
            initial={{ scale: 0.5, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ duration: 1.5 }}
        >
            <h2>Get More on Product</h2>
            <div className="video-container">
                <iframe
                    src="https://player.vimeo.com/video/884550225?badge=0&amp;autopause=0&amp;quality_selector=1&amp;player_id=0&amp;app_id=58479"
                    frameborder="0"
                    allow="autoplay; fullscreen; picture-in-picture"
                    style={{ width: '100%', height: '100%' }}
                    title="Re:Delegate AI">
                </iframe>
            </div>
        </motion.div>
    );
}

export default ShowcaseVideo;
