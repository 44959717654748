import React from 'react';
import Typed from 'react-typed';
import './style.css';

const Index = () => {
    const scrollDown = () => {
        window.scroll({
            top: window.innerHeight * 1.1, // Scrolls 20% of viewport height
            left: 0,
            behavior: 'smooth',
        });
    };

    return (
        <div className="landing-page">
            <Typed
                strings={['communication assistant',  'ai enabling paradigm', 're:delegate ai']}
                typeSpeed={65}
                backSpeed={50}
                loop
                className="typed-text"
                cursorChar="|" /* This adds a custom cursor */
            />
            <div className="scroll-indicator" onClick={scrollDown}>&#8595;</div>
        </div>
    );
};

export default Index;
