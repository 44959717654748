import React from 'react';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';
import './style.css';

const Calendly = () => {
    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1.5 }}
        >
            <div className="calendly-section">
                <h2>Book a Call for Demo</h2>
                <div className="calendly-inline-widget" data-url="https://calendly.com/v-ratushnyi/intro" style={{ minWidth: '320px', height: '700px' }}></div>
                <Helmet>
                    <script src="https://assets.calendly.com/assets/external/widget.js" type="text/javascript" async />
                </Helmet>
            </div>
        </motion.div>
    );
};

export default Calendly;
